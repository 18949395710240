<footer class="w-full bg-neutral-900 z-30" id="footer">
  <div class="container flex flex-col">
    <div class="w-full flex flex-col lg:flex-row justify-center lg:justify-between items-center pt-6 lg:py-6">
      <a [routerLink]="['/']">
        <img src="assets/img/logos/logo-mc.png"
             width="324"
             height="89"
             i18n-alt="@@footer_mc"
             alt="Muzički centar"
             class="w-[162px] h-[45px]">
      </a>
      <div class="flex flex-col lg:flex-row justify-center items-center text-14 font-700 leading-140 text-neutral-white
      lg:space-x-12 lg:pb-0 pt-9 lg:pt-0 space-y-6 lg:space-y-0">
        <a [routerLink]="['/']" class="hover:underline uppercase" i18n="@@general_homepage">Početna</a>
        <a [routerLink]="[repertoireRoute]" class="hover:underline uppercase" i18n="@@general_repertoaire">Repertoar</a>
        <a [routerLink]="[contactRoute]" class="hover:underline uppercase" i18n="@@general_contact">Kontakt</a>
        <span class="ml-4 pb-1 lg:pb-0">
        <app-brand-icons></app-brand-icons>
      </span>
      </div>
    </div>
    <div class="h-05 w-full bg-neutral-400"></div>
    <div class="w-full py-8 flex flex-col lg:flex-row lg:space-x-4 text-neutral-white lg:pb-8 lg:min-h-80">

      <div
        class="w-full lg:w-8/24 flex justify-center text-center lg:text-left lg:justify-start order-1 lg:order-2">
        <div class="font-700 flex flex-col items-start text-20 leading-140 pb-9 lg:pb-0">
          <span class="w-full font-750 font-everett" i18n="@@footer_contact">Kontakt</span>
          <div class="flex flex-col font-700 text-neutral-400 text-16 leading-150">
            <span class="mt-4" i18n="@@footer_phone">Telefon</span>
            <a href="tel:+382 20 653 749" class="mt-1 font-400 hover:underline">+382 20 653 749</a>
            <a href="tel:+382 68 245 980" class="mt-1 font-400 hover:underline">+382 68 245 980</a>
            <span class="mt-6">E-mail:</span>
            <a href="mailto:info@mccg.me" class="font-400 hover:underline">info@mccg.me</a>
          </div>
        </div>
      </div>
      <div class="flex w-full text-center lg:text-left lg:w-8/24 order-2 lg:order-3">
        <div
          class="w-full lg:w-20/24 flex flex-col text-center lg:text-left items-center lg:items-start font-700
          text-neutral-400 text-16 leading-150 mb-8 lg:mb-0">
          <span class="font-750 font-everett text-20 leading-140 text-neutral-white" i18n="@@footer_address">Adresa</span>
          <span class="mt-4 font-400 px-5 lg:px-0"
                i18n>V Proleterske brigade 4/1 <br> 81 000 Podgorica, Crna Gora</span>
        </div>
        <div class="hidden lg:flex lg:w-4/24"></div>
      </div>
      <div class="w-full lg:w-8/24 flex text-center lg:text-left justify-center lg:justify-start order-3 lg:order-4">
        <div class="font-700 flex flex-col items-start text-20 leading-140 pb-9 lg:pb-0">
          <span class="w-full font-750 font-everett" i18n="@@footer_ticket_office">Biletarnica</span>
          <div class="flex flex-col font-700 text-neutral-400 text-16 leading-150">
            <span class="mt-4" i18n="@@footer_phone">Telefon</span>
            <a href="tel:+38268004900" class="mt-1 font-400">+382 68 004 900</a>
            <span class="mt-2" i18n="@@footer_monday_friday">Ponedjeljak - Petak:</span>
            <span class="font-400">09:00 - 15:00</span>
            <span class="mt-2" i18n="@@footer_saturday_monday">Subota - Ponedjeljak:</span>
            <span class="font-400 px-5 lg:px-0"
                  i18n="@@footer_two_houres_before_event_start">Dva sata prije početka događaja</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full bg-neutral-800">
    <div class="container flex flex-col lg:flex-row justify-center lg:justify-between items-center py-3 font-400 text-14
    leading-140 text-neutral-400">
      <span class="text-center">©  Muzički centar Crne Gore {{ currentYear }}. Sva prava zadržana.</span>
      <span class="flex mt-2 lg:mt-0">Made with
        <fa-icon [icon]="['fas', 'music']" class="w-4 h-4 flex justify-center items-center mt-1 mx-2"></fa-icon>
        by <a href="https://www.fleka.me/" target="_blank" class="font-700 ml-1"> fleka</a></span>
    </div>
  </div>
</footer>
